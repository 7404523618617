<template>
  <div>
    <div class="background-pattern-bottom">
      <div class="image" :style="[{backgroundImage: 'url('+ require('../../assets/img/about/bg2.png') +')'}]" />
      <div>
        <div class="main" style="padding-bottom: unset;">
          <p class="p-title">
            全球智慧建站平台
          </p>
          <p class="sub-title">
            唯有客户的成功，才是我们的成功！
          </p>

          <el-button class="title-btn" type="primary" size="medium" @click="gotoLogin()">
            快速开始建站
          </el-button>

          <p class="c-title" style="margin-bottom: 0;">
            深圳市万鲸科技有限公司简介
          </p>
          <div class="txt" style="margin-top: 3rem;">
            <p class="s-txt">
              深圳市万鲸科技有限公司成立于2019年，公司通过去中心化的智慧云端生态体系赋能中小企业实现数字化转型，
            </p>
            <p class="s-txt">
              通过科技驱动商业革新，让商业变得更智慧!目前公司万鲸云平台旗下拥有鲸旦saas、SKU+、Vllshop品牌是公司电商团队为企业
            </p>
            <p class="s-txt">
              “商城+智能分销+供应链＋品牌推广”的电商服务一体化解决方案。帮助客户在新零售时代实现数据化转型，帮助企业提高运营效率和盈利能力。
            </p>
            <p class="s-txt">
              Vllshop 团队成员在持续增长，拓展更多的市场区域，致力于打造一个全球的跨境电商生态圈。
            </p>
          </div>

          <img class="img-clas" src="../../assets/img/about/our.png" alt="">

          <p class="c-title" style="letter-spacing:10px;">
            万鲸环境
          </p>

          <img class="img-clas" src="../../assets/img/about/wjEnvironment.png" alt="">
          <div class="flexT">
            <img class="flexTimg" src="../../assets/img/about/one.png" alt="">
            <img class="flexTimg" src="../../assets/img/about/two.png" alt="">
            <img class="flexTimg" src="../../assets/img/about/three.png" alt="">
          </div>
          <p class="c-title">
            企业文化与精神
          </p>
        </div>
        <Certify />
      </div>
      <div class="main">
        <p class="s-txt">
          愿景：
        </p>
        <p class="s-txt">
          成为中小企业和电商创业者的首选伙伴！
        </p>
        <p class="s-txt">
          使命：
        </p>
        <p class="s-txt">
          通过科技驱动商业革新，让商业变得更智慧！
        </p>
        <p class="s-txt">
          致力于通过产品和服务，助力企业数字化转型！
        </p>
        <p class="s-txt">
          理念：
        </p>
        <p class="s-txt">
          服务至上，正直诚信，精益求精！
        </p>

        <p class="c-title" style="margin-top:60px;margin-bottom:30px">
          地理位置
        </p>
        <p class="s-txt">
          广东省深圳市南山区北京航空航天大厦1座7003
        </p>
        <div class="bigmap">
          <div id="search" class="mappp computer" />
          <!-- <img class="pcmap" src="../../assets/img/about/pcmap.png" alt=""> -->
          <!-- <img class="pcmap" src="../../assets/img/about/mapL.png" alt=""> -->
        </div>

        <!-- <img class="mobilemap" src="../../assets/img/about/mobilemap.png" alt=""> -->

        <img class="computer" src="../../assets/img/about/computer.png" alt="">

        <p class="c-title" style="margin-top:30px">
          欢迎加入我们
        </p>

        <div class="inputbox">
          <input v-model="yourphone" type="text" class="input-connection" placeholder="请输入你的联系方式">
          <el-button class="btn-connection" type="primary" @click="commit()">
            提交
          </el-button>
        </div>
      </div>
    </div>
    <AboutUsFooterBanner></AboutUsFooterBanner>
  </div>
</template>

<script>
// import Certify from 'components/Certify'
import Certify from './components/imgCard.vue'
import { getusernameToken, removeToken } from '../../utils/cookie'
import { isvalidPhone } from '@/utils/validate'
import { addPhone } from '@/api/register'
import AboutUsFooterBanner from '@/layout/footer/aboutUsFooterBanner'

export default {
  components: {
    AboutUsFooterBanner,
    Certify
  },
  data() {
    return {
      yourphone: ''
    }
  },
  mounted() {
    setTimeout(function() {
      let zoom = 16
      const initw = document.body.clientWidth
      if (initw > 500) {
        zoom = 16
      } else {
        zoom = 14
      }
      // 地图1
      // eslint-disable-next-line no-undef
      var map = new AMap.Map('search', {
        resizeEnable: true, // 是否监控地图容器尺寸变化
        zoom: zoom, // 初始化地图层级
        center: [113.941205, 22.527311] // 初始化地图中心点
      })

      // eslint-disable-next-line no-undef
      var endMarker = new AMap.Marker({
        position: map.center,
        icon: require('../../assets/img/about/dir-marker.png')
      })
      map.add([endMarker])

      // 监听宽度
      // const that = this
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          if (window.screenWidth < 500) {
            map.setZoom(14)
          } else {
            map.setZoom(16)
          }
        })()
      }
    }, 0)
  },
  methods: {
    commit() {
      if (!isvalidPhone(this.yourphone)) {
        this.$message({
          message: '格式不对',
          type: 'error',
          duration: 1000
        })
        return
      }
      addPhone(this.yourphone).then(res => {
        if (res.code === 0) {
          this.$message.success('提交成功')
        }
      })
    },
    gotoLogin() {
      removeToken(getusernameToken())
      this.$login()
    }
  }
}
</script>

<style lang="scss" scoped>
.mappp{
  width: 913px;
  height: 528px;
}
::v-deep .amap-icon img{
	width: 35px;
	height: 34px;
}
// flexT
.flexT{
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-top: 40px;
}
.flexTimg + .flexTimg{
  margin-left: 50px;
}
// 地图
.bigmap{
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-top: 0;
}
.pcmap + .pcmap{
  margin-left: 25px;
}

// 电脑
.computer{
  margin: 30px 70px 96px 70px;
}
// 地图大小
.pcmap{
  width: 550px;
  height: 550px;
  margin: 30px 30px 50px 30px;
}
.mobilemap{
  display: none;
  margin: 104px 70px 96px 70px;
}

// 联系我们
.inputbox{
  height: 141px;
  width: 800px;
  border-radius: 24px;
  color: #89ACDD;
  box-shadow: 0px 0px 10px #c4c4c4 !important;
  display: flex;
  align-items: center;
}
.input-connection{
  margin-left: 60px;
  height: 40px;
  line-height: 40px;
  border: unset;
  font-size: 20px;
  flex: 1;
}
.btn-connection{
  width: 180px;
  height: 50px;
  margin: 0 36px;
  border-radius: 24px;
}
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
}
.p-title {
  font-weight: bold;
  font-size: 5rem;
  margin: 80px 0 0 0;
}
// 副标题
.sub-title{
  font-size: 2rem;
  margin: 10px 0 30px 0 ;
}
// 按钮
.title-btn{
    height: 60px;
    width: 250px;
    border-radius: 30px;
    font-size: 2.8rem;
}
// 公司名称
.c-title{
    font-weight: bold;
    font-size: 5rem;
    margin: 104px  0;
}
// 描述文本
.s-txt{
    line-height: 3.6rem;
    // color: #17355e8a;
    // font-size: 2.7rem;
    // text-align: center;
    // font-weight: 300;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(0,8,45,.5);
}
.s-txt:nth-last-of-type(1){
    margin-bottom: 50px;
}
.background-pattern-bottom {
  width: 100%;
  min-height: 150px;
  position: relative;
//   bottom: -150px;
  .image{
    width: 100%;
    height: 100%;
    position: absolute;
    background:no-repeat;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
</style>

<style lang="scss" scoped>
// 适配
//  width:320px-479px
@media only screen and (min-width:320px) and (max-width:479px) {
  .p-title {
    font-size: 3rem;
    margin: 40px 0 0 0;
  }
// 副标题
  .sub-title{
    font-size: 2rem;
    margin: 10px 0 40px 0 ;
  }
  // 按钮
  .title-btn{
    height: 50px;
    width: 280px;
    border-radius: 30px;
    font-size: 2rem;
  }
   .img-clas{
    // transform:scale(0.22,0.22) !important
    height: 211px;
    width: 315px;
  }
  // 标题
  .c-title{
    font-size: 2.9rem;
    margin: 15px 0 15px 0;
  }
  .s-txt{
    line-height: 2.9rem;
    // color: #17355e8a;
    // font-size: 2.7rem;
    // text-align: center;
    // font-weight: 300;

    font-size: 14px;
    font-weight: 400;
  }

  // 三个图片的边距
  .flexT{
    justify-content: center;
  }
  .flexTimg{
    width: 315px;
    height: 445px;
    // transform: scale(0.7);
  }
  // 三个图片的边距
  .flexTimg + .flexTimg{
    margin-left: unset;
    margin-top: 18px;
  }

  // 处理电脑图片
  .computer{
    margin: 20px;
    height: 160px;
    width: 275px;
    // transform: scale(0.3);
  }
  .pcmap{
    display: none;
  }

  .mobilemap{
    display: unset;
    width: 325px;
    height: 525px;
    margin: 20px;
    // transform: scale(0.3);
  }

  // 联系方式
  // 联系我们
.inputbox{
  height: 70px; //改
  width: 320px; //改
  border-radius: 24px;
  box-shadow: 0px 0px 8px #c4c4c4 !important;
}
.input-connection{
  margin-left: 15px;
  height: 32px;
  line-height: 32px;
  border: unset;
  font-size: 16px;
  flex: 1;
}
.btn-connection{
  width: 70px;
  height: 40px; //改
  margin: 0 18px;
  border-radius: 24px;
}
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width:480px) and (max-width:575px) {
  .p-title {
    font-size: 3rem;
    margin: 40px 0 0 0;
  }
// 副标题
  .sub-title{
    font-size: 2rem;
    margin: 10px 0 40px 0 ;
  }
  // 按钮
  .title-btn{
    height: 50px;
    width: 280px;
    border-radius: 30px;
    font-size: 2rem;
  }
  .img-clas{
    // transform:scale(0.3,0.3) !important
    height: 287px;
    width: 430px;
  }
  // 标题
  .c-title{
    font-size: 3rem;
    margin: 30px 0 30px 0;
  }

  // 三个图片的边距
  .flexT{
    justify-content: center;
  }
  .flexTimg{
    width: 404px;
    height: 573px;
    // transform: scale(0.9);
  }
  // 三个图片的边距
  .flexTimg + .flexTimg{
    margin-left: unset;
    margin-top: 20px;
  }

  // 处理电脑图片
  .computer{
    margin: 25px;
    height: 211px;
    width: 365px;
    // transform: scale(0.4);
  }
  .pcmap{
    display: none;
  }
  .mobilemap{
    display: unset;
    width: 432px;
    height: 702px;
    margin: 60px;
    // transform: scale(0.4);
  }

  // 联系方式
  // 联系我们
.inputbox{
  height: 80px; //改
  width: 430px; //改
  border-radius: 24px;
  box-shadow: 0px 0px 8px #c4c4c4 !important;
}
.input-connection{
  margin-left: 30px;
  height: 40px;
  line-height: 40px;
  border: unset;
  font-size: 20px;
  flex: 1;
}
.btn-connection{
  width: 150px;
  height: 40px; //改
  margin: 0 36px;
  border-radius: 24px;
}
}
</style>

<style lang="scss" scoped>
//  width:576px-767px
@media only screen and (min-width:576px) and (max-width:767px) {
.img-clas{
    // transform:scale(0.35,0.35) !important
    height: 335px;
    width: 500px;
  }
  // 标题
  .c-title{
    font-size: 3rem;
    margin: 60px 0 60px 0;
  }

  // 三个图片的边距
  .flexT{
    justify-content: center;
  }
  .flexTimg{
    // width: 225px;
    // height: 320px;
    // transform: scale(0.5);
  }
  // 三个图片的边距
  .flexTimg + .flexTimg{
    margin-left: unset;
    margin-top: 30px;
  }

  // 处理电脑图片
  .computer{
    margin: 30px;
    height: 259px;
    width: 448px;
    // transform: scale(0.7);
  }
  // 两张地图垂直
  .bigmap{
    justify-content: center;
  }
  .pcmap{
    display: none;
  }
  .mobilemap{
    display: unset;
    width: 480px;
    height: 790px;
    margin: 60px;
    // transform: scale(0.45);
  }

  // 联系方式
  // 联系我们
.inputbox{
  height: 100px; //改
  width: 520px; //改
  border-radius: 24px;
  box-shadow: 0px 0px 8px #c4c4c4 !important;
}
.input-connection{
  margin-left: 30px;
  height: 40px;
  line-height: 40px;
  border: unset;
  font-size: 20px;
  flex: 1;
}
.btn-connection{
  width: 150px;
  height: 50px; //改
  margin: 0 36px;
  border-radius: 24px;
}
}
</style>

<style lang="scss" scoped>
//  width:768px-991px
@media only screen and (min-width:768px) and (max-width:991px) {
  .img-clas{
    // transform:scale(0.5,0.5) !important
    height: 480px;
    width: 720px;
  }
  // 标题
  .c-title{
    font-size: 3rem;
    margin: 60px 0 60px 0;
  }

  // 三个图片的边距
  .flexTimg{
    width: 225px;
    height: 320px;
    // transform: scale(0.5);
  }
  // 三个图片的边距
  .flexTimg + .flexTimg{
    margin-left: 25px;
  }

  // 处理电脑图片
  .computer{
    margin: 40px;
    height: 370px;
    width: 640px;
    // transform: scale(0.7);
  }
  // 两张地图垂直
  .bigmap{
    justify-content: center;
  }

  // 处理地图图片
  .pcmap{
    margin: 40px;
    height: 644px;
    width: 644px;
    // transform: scale(0.7);
  }

  // 联系方式
  // 联系我们
.inputbox{
  height: 120px; //改
  width: 650px; //改
  border-radius: 24px;
  box-shadow: 0px 0px 8px #c4c4c4 !important;
}
.input-connection{
  margin-left: 60px;
  height: 40px;
  line-height: 40px;
  border: unset;
  font-size: 20px;
  flex: 1;
}
.btn-connection{
  width: 150px;
  height: 50px; //改
  margin: 0 36px;
  border-radius: 24px;
}
}
</style>

<style lang="scss" scoped>
//  width:992px-1199px
@media only screen and (min-width:992px) and (max-width:1199px){
   .img-clas{
    // transform:scale(0.6,0.6) !important
    height: 575px;
    width: 861px;
  }
  // 标题
  .c-title{
    font-size: 3rem;
    margin: 60px 0 60px 0;
  }

  // 两张地图垂直
  .bigmap{
    justify-content: center;
  }

  // 三个图片的边距
  .flexTimg{
    width: 272px;
    height: 380px;
    // transform: scale(0.6);
  }
  // 三个图片的边距
  .flexTimg + .flexTimg{
    margin-left: 25px;
  }

}
</style>
<style lang="scss" scoped>
//  width:992px-1199px
@media only screen and (min-width:1200px) and (max-width:1500px){
  // 处理图片
  .img-clas{
    width: 1148px;
    height: 767px;
  }

  // 两张地图垂直
  .bigmap{
    justify-content: center;
  }

  .flexTimg{
    width: 320px;
    height: 450px;
  }
  // 三个图片的边距
  .flexTimg + .flexTimg{
    margin-left: 95px;
  }
}
</style>
